<template><div></div></template>

<script>

  export default {

      name: "OverlayComponent",

      data(){
          return{
              default_overlay: 'constraints',
              constraints: {
                'source': {
                  'name': 'constraints',
                  'type': 'vector',
                  'tiles': [],
                  'minzoom': 6,
                  'maxzoom': 14,
                },
                'layer': {
                    'id': 'constraints',
                    'type': 'fill',
                    'source': 'constraints',
                    'source_layer': 'tiles.fs_constraints',
                    'paint':  {'fill-outline-color': 'transparent',
                               'fill-color': '#FA8072', 'fill-opacity': .8
                     }
                  },
              },
              no_constraints: {
                'source': {
                  'name': 'no_constraints',
                  'type': 'vector',
                  'tiles': [],
                  'minzoom': 6,
                  'maxzoom': 14,
                },
                'layer': {
                    'id': 'no_constraints',
                    'type': 'fill',
                    'source': 'no_constraints',
                    'source_layer': 'tiles.fs_no_constraints',
                    'paint':  {'fill-outline-color': 'transparent',
                               'fill-color': '#B911F0', 'fill-opacity': .8
                    }
                },
              }
          }
      },

      props: {
        'popup': {
          type: Object,
          default: null 
        },
        'map': {
          type: Object,
          default: null 
        },
        'opacity': {
          type: Number,
          default: .8 
        },
        'selected_constraints':{
          type: Array,
          default: () => ([0, 0])
        },
        'selection': {
          type: Object,
          default: null 
        },
        'style_change': {
          type: Boolean,
          default: null 
        },
      },

      watch:{

        opacity: function(){
          this.setOpacity();
        },

        selected_constraints: {
            handler: function() {
              this.updateLayers();
            },
            deep: true
        },

        selection: {
          deep: true,
          handler() {
            this.removeLayers();
            if(!this.selection.id){
              return;
            }
            this.updateLayers();
          }
        },

        style_change: function(){
          this.addSources();
          setTimeout(
            ()=>this.addLayers(true)
          ,1000);
        }
      },

      methods:{

        addInteractions(){
          this.removeInteractions();
          this.map.on('mouseleave', this.constraints.layer.id, 
                                    (e) => this.removeHoverHandler(e, 0));
          this.map.on('mousemove', this.constraints.layer.id, 
                                   (e) => this.addHoverHandler(e, 0));
          this.map.on('mouseleave', this.no_constraints.layer.id, 
                                    (e) => this.removeHoverHandler(e, 1));
          this.map.on('mousemove', this.no_constraints.layer.id, 
                                   (e) => this.addHoverHandler(e, 1));
        },

        addLayers(force = false){
          this.removeLayers(force)
          this.addSources();
          if(this.selection.id){
            if(this.selected_constraints[0]){
              if (!this.map.getLayer(this.constraints.layer.id)) {
                this.map.addLayer({
                  'id': this.constraints.layer.id,
                  'type': this.constraints.layer.type,
                  'source': this.constraints.source.name, 
                  'source-layer': this.constraints.layer.source_layer,
                  'paint': this.constraints.layer.paint
                });
                this.map.triggerRepaint();
              }
            }
            if(this.selected_constraints[1]){
              if (!this.map.getLayer(this.no_constraints.layer.id)) {
                this.map.addLayer({
                  'id': this.no_constraints.layer.id,
                  'type': this.no_constraints.layer.type,
                  'source': this.no_constraints.source.name, 
                  'source-layer': this.no_constraints.layer.source_layer,
                  'paint': this.no_constraints.layer.paint
                });
                this.map.triggerRepaint();
              }
            }
            this.setOpacity();
            this.addInteractions();
          }
        },

        addHoverHandler(e, layer){
          const coordinates = e.lngLat;
          let text ;
         
          switch(layer){
            case 0:
              text = 'constrained';
              break;
            case 1:
              text = 'remaining';
              break;
            default:
              text = null;
          }
          if(text){ 
            this.map.getCanvas().style.cursor = 'pointer';        
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
            this.popup.setLngLat(coordinates).setHTML(text).addTo(this.map);
          }
        },

        addSources(){
            if(!this.map.getSource(this.no_constraints.source.name)){
              this.map.addSource(this.no_constraints.source.name, {
                  'type': this.no_constraints.source.type,
                  'tiles': this.no_constraints.source.tiles,
                  'minzoom': this.no_constraints.source.minzoom,
                  'maxzoom': this.no_constraints.source.maxzoom,
              });
            }
            if(!this.map.getSource(this.constraints.source.name)){
              this.map.addSource(this.constraints.source.name, {
                  'type': this.constraints.source.type,
                  'tiles': this.constraints.source.tiles,
                  'minzoom': this.constraints.source.minzoom,
                  'maxzoom': this.constraints.source.maxzoom,
              });
            }
        },

        setOpacity(){
          if(this.map.getLayer(this.constraints.layer.id)){
            this.map.setPaintProperty(this.constraints.layer.id,
            'fill-opacity', this.opacity);
          }
          if(this.map.getLayer(this.no_constraints.layer.id)){
            this.map.setPaintProperty(this.no_constraints.layer.id,
            'fill-opacity', this.opacity);
          }
        },

        updateTileUrls(){
          const base =  'https://d3u20sxscxyinr.cloudfront.net/geolimited/muni';
                      
          let url = base 
                    + '/constraints'
                    + '/' + this.selection['id'] 
                    + '/{z}/{x}/{y}.pbf';
          this.constraints.source.tiles = [url]; 
          if(this.map.getSource('constraints')){
            this.map.getSource('constraints').setTiles([url]);
          }
          url = base 
                    + '/no_constraints'
                    + '/' + this.selection['id'] 
                    + '/{z}/{x}/{y}.pbf';
          this.no_constraints.source.tiles = [url];
          if(this.map.getSource('no_constraints')){
            this.map.getSource('no_constraints').setTiles([url]);
          }
        },

        removeHoverHandler(){ 
          this.map.getCanvas().style.cursor = '';
          if(this.popup){
            this.popup.remove();
          }
        },

        removeInteractions(){
          this.map.off('mouseleave', this.constraints.layer.id, 
                                    (e) => this.removeHoverHandler(e, 0));
          this.map.off('mousemove', this.constraints.layer.id, 
                                   (e) => this.addHoverHandler(e, 0));
          this.map.off('mouseleave', this.no_constraints.layer.id, 
                                    (e) => this.removeHoverHandler(e, 1));
          this.map.off('mousemove', this.no_constraints.layer.id, 
                                   (e) => this.addHoverHandler(e, 1));
        },

        removeLayers(force = false){ 

          if(this.map.getLayer(this.constraints.layer.id)){
            if((!this.selected_constraints[0] || force)   ){
                this.map.removeLayer(this.constraints.layer.id);
                this.map.off('click', this.constraints.layer.id, this.handleLayerClick);
            }
          }
          if(this.map.getLayer(this.no_constraints.layer.id)){
            if((!this.selected_constraints[1]) || force){
                this.map.removeLayer(this.no_constraints.layer.id);
                this.map.off('click', this.no_constraints.layer.id, this.handleLayerClick);
            }
          } 
          this.selection_change = false;
        },

        updateLayers(){ 
          // this.constraints.source.tiles.pop();
          // this.no_constraints.source.tiles.pop();
          this.updateTileUrls();
          this.addLayers();

          // setTimeout(() => {
          //   this.addLayers();
          // }, 300);
        }
      }
  }

</script>
