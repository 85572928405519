<template>
  <div v-show="show"
       id="graph-window"
       class="flex shrink-0 grow flex-wrap p-2 overflow-y-auto border-2 
              border-neutral-100 hover:shadow-xl"
       :class="{'basis-1/2': !full_screen, 
                'min-h-[50%]': !full_screen,
                'max-h-[50%]': !full_screen,
                'basis-1': full_screen,
                'h-full': full_screen}">
    <div class="absolute top-0 float-right h-6 z-50"
         :class="{'w-1/2': !full_screen,
                  'w-full': full_screen}"
         style="font-variation-settings:  'wght' 700, 'GRAD' 0, 'opsz' 48;"
         id="table-expander">
      <span class="font-symbol material-symbols-outlined w-6 float-right mr-4 
                 mt-3 bg-white"
            v-show="!full_screen"
            @click="full_screen = true">fullscreen</span> 
      <span class="font-symbol material-symbols-outlined w-6 float-right mr-4 
                   mt-3"
            v-show="full_screen"
            @click="full_screen = false">fullscreen_exit</span> 
    </div>
    <div class="h-6 p-2 w-1/2 text-xs md:text-sm 
            lg:text-base xl:text-lg 2xl:text-2xl"
          :class="{'text-left': !full_screen,
                  'text-center': full_screen,
                  'w-1/2': !full_screen,
                  'w-full': full_screen,
                  'text-fs_orange': !dashboard,
                  'text-njm_red': dashboard}">
            <span v-if="show_graph">Total 
                Remaining Acreage by Sewer Service</span>
    </div>

    <div class="font-mono font-bold text-center w-full mx-auto
               text-6xl" v-if="!show_graph"
         :class="dashboard?'text-njm_red':'text-fs_orange'">GRAPH</div>

    <div id="chart" v-show="(values[0] + this.values[1] > 0) && show_graph"
         class="w-full h-full pr-4 mt-8 -mb-2">
        <apexchart type="bar" 
                   :options="chartOptions" 
                   :series="series"></apexchart>
    </div>

    <div class="font-mono font-bold text-center w-full mx-auto
          text-xs md:text-sm  lg:text-base xl:text-lg 2xl:text-2xl" 
         :class="dashboard?'text-njm_red':'text-fs_orange'"
          v-if="(values[0] + this.values[1] == 0) && show_graph"
    >No remaining land.</div>
  </div>
</template>

<script>
  import VueApexCharts from "vue3-apexcharts";  
  import constrained from '../assets/data/constrained.json';

  export default {
                
    name: 'GraphComponent',

    components: {
      'apexchart': VueApexCharts
    },

    props:[
      'dashboard',
      'munis',
      'selection',
      'visible'
    ],
            
    emits: [
      'full_screen',
    ],

    watch:{
      full_screen: function(){
        this.$emit('full_screen', this.full_screen, this.$.type.name);
      },

      selection: {
        deep: true,
        handler(){
          if(!this.selection.id){
            this.clearChart();
            return;
          }
          this.show_graph = true;
          const arr = this.munis.filter(({ type }) => type === 'm');

          this.stats = arr.filter(({ id }) => id === 
                                                   parseInt(this.selection.id));
          this.makeGraph();
        }
      },

      visible: {
        deep: true,
        handler(){
          if(this.visible[this.$.type.name]){
            this.show = true;
          }else{
            this.show = false;
          }
        }
      },
    },

    data(){
      return{
        constrained,
        chartOptions: {},
        full_screen: false,
        height: 0,
        series: [],
        show: true,
        show_graph: false,
        stats: {},
        values: [0, 0]
      }
    },

    mounted(){
      this.addResizer();
    },

    methods:{

      addResizer(){
        const observer = new ResizeObserver(()=> {
          this.height = document.getElementById('graph-window').offsetHeight/1.5;
          if(this.selection){
            this.makeGraph();
          }
        });
        observer.observe(document.getElementById('graph-window'));
      },

      clearChart(){
        this.show_graph = false;
      },

      makeGraph(){
        const arr = this.constrained
          .filter(({ m }) => m == this.selection.id);
        const sewer  =  arr.filter(({p,s}) => p ===  null && s != null);

        let options = {
          series: [],
          chart: {
          toolbar:{show: false},
          type: 'pie',
          redrawOnWindowResize: true,
          redrawOnParentResize: true
          }
        }
        
        let rec = (sewer.filter(({s}) => s ===  true))[0];
        
        if(!rec) { return; }
        
        if(Object.keys(rec).length != 0){
          this.values[0] = Number(Number((Math.round(rec.na * 10)/10).toFixed(1)));
        }
        options.series.push(this.values[0]);

        rec = (sewer.filter(({s}) => s !==  true))[0];
        if(Object.keys(rec).length != 0){
          this.values[1] = Number((Math.round(rec.na * 10)/10));
        }
        options.series.push(this.values[1]);

        options['dataLabels'] = {
          enabled: true,
          enabledOnSeries: [1],
          formatter: function (val) {
            let valueFormatted = val.toFixed(1);
            // return opt.w.globals.labels[opt.dataPointIndex] + ": " + val;
            return valueFormatted;
          },
        },

        options['colors'] = ['#00ff00', '#ff0000'],

        options.labels = ['Sewered ' + this.values[0] + ' acs.',
                          'Not Sewered ' + this.values[1] + ' acs.'],


        // const data = this.stats[0];
        // let series = [];
        // let options = {};

        // series.push({name: 'Agriculture',
        //  data: [data.f8, data.f9, data.f10, data.f11, data.f12, data.f13, 
        //         data.f14]});
        // series.push({name: 'Forest',
        //  data: [data.f1, data.f2, data.f3, data.f4, data.f5, data.f6,
        //         data.f7]});
        // series.push({name: 'Barren',
        //  data: [data.f22, data.f23, data.f24, data.f25, data.f26, data.f27, 
        //         data.f28]});
        // series.push({name: 'Water',
        //  data: [data.f15, data.f16, data.f17, data.f18, data.f19, data.f20, 
        //         data.f21]});
        // series.push({name: 'Wetland',
        //  data: [data.f29, data.f30, data.f31, data.f32, data.f33, data.f34, 
        //         data.f35]});
        // series.push({name: 'Urban',
        //  data: [data.f36, data.f37, data.f38, data.f39, data.f40, data.f41, 
        //         data.f42]});

        // options['chart'] = {
        //   height: this.height,
        //   type: 'line',
        //   zoom: {
        //     enabled: false
        //   }
        // },
        // options['dataLabels'] = {
        //   enabled: false
        // },

        // options['colors'] = ['#a6ffa5', '#018200'],
                                       
        // options['stroke'] = {
        //   width: 4,
        //   curve: 'straight',
        //   dashArray: 0
        // },

        // options['title'] =  {
        //   text: data.label,
        //   align: 'left'
        // },
        
        // options['subtitle'] =  {
        //   text: 'Land Cover Over Time',
        //   align: 'left'
        // },
        
        // options['markers']= {
        //   size: 0,
        //   hover: {
        //     sizeOffset: 6
        //   }
        // },
        // options['xaxis'] = {
        //   categories: [1986, 1995, 2002, 2007, 2012, 2015, 2020],
        //   title: {
        //       text: 'Year'
        //   }
        // },

        // options['yaxis'] = {
        //   labels: {
        //     formatter: function(val) {
        //       return Math.round(val).toLocaleString('en-US');
        //       }
        //     },
        //     title: {
        //         text: 'Acres'
        //     }
        // },

        // options['markers'] = {
        //   size: 5,
        //   colors: ['#a6ffa5', '#018200', '#fffbc3', 
        //                                '#0000c8', '#00d1dc', '#ffa5a5'],
        //   strokeColors:['#a6ffa5', '#018200', '#fffbc3', 
        //                                '#0000c8', '#00d1dc', '#ffa5a5'],
        //   strokeWidth: 2,
        //   strokeOpacity: 0.9,
        //   strokeDashArray: 0,
        //   fillOpacity: 1,
        //   discrete: [],
        //   shape: "circle",
        //   radius: 2,
        //   offsetX: 0,
        //   offsetY: 0,
        //   onClick: undefined,
        //   onDblClick: undefined,
        //   showNullDataPoints: true,
        //   hover: {
        //     size: 8,
        //     sizeOffset: 3
        //   }
        // },

        // options['tooltip'] = {

        //   y: [
        //     {
        //       title: {
        //         formatter: function (val) {
        //           return val
        //         }
        //       }
        //     },
        //     {
        //       title: {
        //         formatter: function (val) {
        //           return val
        //         }
        //       }
        //     },
        //     {
        //       title: {
        //         formatter: function (val) {
        //           return val
        //         }
        //       }
        //     },
        //     {
        //       title: {
        //         formatter: function (val) {
        //           return val + " acres"
        //         }
        //       }
        //     },
        //     {
        //       title: {
        //         formatter: function (val) {
        //           return val
        //         }
        //       }
        //     },
        //     {
        //       title: {
        //         formatter: function (val) {
        //           return val
        //         }
        //       }
        //     }
        //   ]
        // },  
        // options['grid'] = {
        //   borderColor: '#f1f1f1',
        //   xaxis: {
        //     'lines': {show: true}
        //   }
        // },
        
        // this.series = series;
        this.chartOptions = options;
      }
    }
  }

</script>