<template>
 
  <div class="h-auto pl-4 py-5 text-xs md:text-sm 
              lg:text-base xl:text-xl 2xl:text-2xl align-top items-center
              border-b-2 border-neutral-100">
      <div class="flex flex-wrap pl-4">
        <div data-id="land_1" 
             class="basis-7/12">
          <input type="checkbox" name="land_1" id="land_1" 
                 data-id="land_1" 
                 v-model="selected_constraints[1]" checked>
          <label for="land_1"  data-id="land_1" 
                 class="pl-2 text-slate-700">Remaining Unrestricted Land</label>
        </div>
        
        <div data-id="land_2" 
             class="basis-5/12">
          <input type="checkbox" name="land_2" id="land_3" 
                 data-id="land_2" 
                 v-model="selected_constraints[0]">
          <label for="land_2"  data-id="land_2" 
                 class="pl-3 text-slate-700">Constrained Land</label>
        </div>
      </div>
  </div>

</template>

<script>


  export default {

    name: "ConstraintMenu",

    emits:[
      'selected_constraints'
    ],

    props:[
      'dashboard'
    ],

    data(){
      return {
        selected_constraints: [false,true]
      }
    },

    watch:{
      selected_constraints: {
            handler: function() {
              this.$emit('selected_constraints', this.selected_constraints);
            },
            deep: true
        }
    }
  }

</script>
