<template>
 
  <div class="h-auto pl-4 text-xs md:text-sm 
              lg:text-base xl:text-xl 2xl:text-2xl align-top items-center">
      <div class="flex-none w-full pt-5 border-b-2 border-neutral-100 
                  text-center">
        <select class="w-64 border border-neutral-200 rounded shadow"
                :class="selected_geography?'text-neutral-800':
                        'text-neutral-400'"
                v-if="!server_error" 
                id="select-region"
                v-model="selected_id">
          <option class="text-center" value="-1" selected>Municipality</option>
          <option v-for="option in options.selected" v-bind:value="option.id"
                 v-bind:key="option.id">{{ option.unit }} </option>
        </select>
        <div class="font-light text-xs lg:text-sm 2xl:text-base text-blue-600
                    text-center my-2 h-6"
        ><span v-show="selected_id" 
              class="hover:underline hover:cursor-pointer"
              @click="openLink"
        >View in NJMAP</span> &nbsp;</div>
        <span v-if="server_error" class="text-red-600">Server error: 
          {{ server_error }}</span>
      </div>
  </div>

</template>

<script>

  import axios from 'axios'

  export default {

    name: "BoundariesComponent",

    props:[
      'dashboard',
      'menu',
      'map',
      'map_loaded',
      'munis',
      'popup'
    ],

    emits:[
      'selection'
    ],

    data(){
      return{
          current_layer_id: null,
          layers: {
            'municipality': {
              'id': 'boundaries-municipality',
              'type': 'fill',
              'source': 'boundaries',
              'source_layer': 'tiles.municipalities'
            },
          }, 
          options: { 
            municipalities: [], 
            selected: [], 
          },
          paint: {
            'boundaries-municipality': {
                'fill-color': 'rgba(220,220,220,.8)',
                'fill-outline-color': '#ffffff',
                'fill-opacity': 0,
                'fill-opacity-transition': { duration: 500 }
              },
          },
          region_select: null,
          selected_id: null,
          selected_geography: null,
          server_error: null,
          source: {
            'name': 'boundaries',
            'type': 'vector',
            'tiles': ['https://d20jbwevdggj78.cloudfront.net/'
                      + 'boundaries/fill/{z}/{x}/{y}.pbf?2'],
            'minzoom': 6,
            'maxzoom': 12,
          },
        }
    },

    computed: {

      bounds: function(){
        const arr = this.munis.filter(({ type }) => type === 'm');

        const data =  arr.filter(({ mun_code }) => mun_code === 
                      this.selected_id);

        return data[0].bounds;
      }
    },

    watch:{
      map_loaded: function(){
          this.addSource();
          this.addGeoLayer();
      },

      selected_geography: function(){
        switch(this.selected_geography){
          // case 'county':
          //   this.options.selected = this.options.counties;
          //   break;
          // case 'landscape':
          //   this.options.selected = this.options.landscape;
          //   break;
          case 'municipality':
            this.options.selected = this.options.municipalities;
            break;
          // case 'watershed':
          //   this.options.selected = this.options.watersheds;
          //   break;
        }
        this.clearSelection();
        this.emitSelection();
      },

      selected_id: function(){
        this.emitSelection();
        if(this.selected_id){
          this.repaintMap();
          this.zoomToFeature();
        }
      }
    },

    methods:{

      addInteractions(layer_id){
        this.map.on('click', layer_id, (e) => this.addClickHandler(e));
        this.map.on('mouseleave', layer_id, (e) => this.removeHoverHandler(e));
        this.map.on('mousemove', layer_id, (e) => this.addHoverHandler(e));
      },

      addClickHandler(e){
        this.selected_id = e.features[0].properties.c;
      },

      addHoverHandler(e){
        const coordinates = e.lngLat;
        const name = e.features[0].properties.l;
        const id = e.features[0].properties.c;

        if(id == this.selected_id){
          return;
        }

        this.map.getCanvas().style.cursor = 'pointer';        
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        
        this.popup.setLngLat(coordinates).setHTML(name).addTo(this.map);
      },

      addGeoLayer(){
        const layer = this.layers['municipality'];
        
        this.selected_geography = 'municipality';
        this.addSource();
        this.clearOverlays();
        this.map.addLayer(
          { 'id': layer.id,
            'type': layer.type,
            'source': this.source.name, 
            'source-layer': layer.source_layer,
            'paint': this.paint[layer.id]
            }
        );
        this.map.setPaintProperty(layer.id, 'fill-opacity', 0.5);
        this.addInteractions(layer.id);
        this.clearPopup();
        this.current_layer_id = layer.id;
      },

      addSource(){
        if(this.map.getSource(this.source.name)){
            return;
        }     
        this.map.addSource(this.source.name, this.source);
      },

      clearOverlays(){
        this.removeInteractions();
        for(const item of Object.values(this.layers)){
          if(this.map.getLayer(item.id)){
            this.map.setPaintProperty(item.id, 'fill-opacity', 0);
            this.map.removeLayer(item.id);
          }
        }
      },

      clearPopup(){
        if(this.popup){
          this.popup.remove();
        }
      },

      clearSelection(){
        this.selected_id = null;
      },

      emitSelection(){
        const selected = {'geography': this.selected_geography,
                          'id': this.selected_id};
        
        this.$emit("selection", selected);
      },

      getDropdownOptions(){
        const url = 'https://bannmdt6pkkb2sjsd24vfb6u6a0ytsyv.lambda-'
                  + 'url.us-east-1.on.aws/?action=get_boundary_list';

        axios.post(url)

        .then((response) => {
          this.parseDropdownOptions(response.data);
          const el = document.getElementById('select-region');
          setTimeout(()=>{
            el.value = -1;
            el.classList.add('text-gray-500')
          },200);
        })

        .catch((error) => {
          console.log(error);
          if (error.response) {
            this.server_error = error.message;
          } else if (error.request) {
            this.server_error = error.message;
          } else {
            this.server_error =  error.message;
          }
        });
      },

      openLink(){
        const center = this.map.getCenter();
        const zoom = this.map.getZoom() + 1;
        let url = 'https://www.njmap2.com/landchange/remaininglands/?override=1'
        
        url += '&zoom=' + zoom + '&lat=' + center.lat + '&lng=' + center.lng +
       '&sc=0&basemap=Mapbox%20Satellite&layers=9228,521,399,400,401' + 
       '&ois=&oms=&po=';

        window.open(url, "_blank");
      },

      parseDropdownOptions(obj){
        for(const item of obj){
          switch(item.type){
            case 'm':
              this.options.municipalities.push(item);
              break;
          }
        }
      },

      removeInteractions(){
        if(!this.current_layer_id){ 
          return; 
        }
        this.map.off('click', this.current_layer_id, 
                              (e) => this.addClickHandler(e));
        this.map.off('mouseleave', this.current_layer_id, 
                                  (e) => this.removeHoverHandler(e));
        this.map.off('mousemove', this.current_layer_id, 
                                  (e) => this.addHoverHandler(e));
      },

      removeHoverHandler(){ 
        this.map.getCanvas().style.cursor = '';
        if(this.popup){
          this.popup.remove();
        }
      },

      repaintMap(){

        if(!this.selected_id){
          this.map.setPaintProperty(this.current_layer_id, 
                                    this.paint[this.current_layer_id]);
          return;
        }

        this.map.setPaintProperty(this.current_layer_id, 
           
          'fill-opacity',
            ['match', ['get', 'c'],
              this.selected_id, 0,
              0.5
            ]
        )
      },

      zoomToFeature() {
        this.map.fitBounds(this.bounds);
      }
    },

    created() {
      this.getDropdownOptions();
    }
  }

</script>
