<template>
  <div v-show="show"
       class="flex flex-col shrink-0 grow flex-wrap p-2 overflow-y-auto border-2 
             border-neutral-100 hover:shadow-xl w-full h-full"
       :class="{'basis-1/2': !full_screen, 
                'min-h-[50%]': !full_screen,
                'max-h-[50%]': !full_screen,
                'basis-1': full_screen,
                'h-full': full_screen}">
    <div>
      <div class="relative top-0 h-6 w-full z-50"
            style="font-variation-settings:  'wght' 700, 'GRAD' 0, 'opsz' 48;"
            id="table-expander">
        <span class="font-symbol material-symbols-outlined w-6 float-right
                      bg-white"
              v-show="!full_screen"
              @click="full_screen = true">fullscreen</span> 
        <span class="font-symbol material-symbols-outlined w-6 float-right"
              v-show="full_screen"
              @click="full_screen = false">fullscreen_exit</span> 
      </div>

      <div class="font-mono font-bold text-center mt-16 mx-auto w-full h-full
                  text-6xl" v-if="!data_available"
           :class="dashboard?'text-njm_red':'text-fs_orange'">SUMMARY</div>
      <div class="h-full w-full" v-if="data_available">
        <div class="relative top-0 float-left h-6 pl-2 pr-0.5 -mt-4 mb-8 w-full 
        text-xs md:text-sm lg:text-base xl:text-lg 2xl:text-2xl"
              :class="{
                'text-center': full_screen,
                'text-left': !full_screen,
                'text-fs_orange': !dashboard,
                'text-njm_red': !dashboard
              }">
        <span v-if="stats.length==0">No</span>
        <span v-if="stats.length>0">Contiguous</span>
      Remaining Properties<div class="text-xs xl:text-sm"  v-if="stats.length>0"
        >(May consist of multiple parcels.)</div></div>
          <table class="table-fixed w-full h-full" v-if="stats.length>0">
          <tbody>
            <tr>
              <th class="font-normal w-7/12 text-right pr-1">Largest contiguous 
                property:</th>
                <td class="pl-3 text-left">{{ stats[0].max }} acres</td>
            </tr>
            <tr class="bg-neutral-50">
              <th class="font-normal w-7/12 text-right pr-1">Median remaining 
                property size:</th>
                <td class="pl-3 text-left">{{ stats[0].median }} acres</td>
            </tr>
            <tr class="bg-neutral-50">
              <th class="font-normal w-7/12 text-center py-2" colspan="2">Size 
                Distribution</th>
            </tr>
            <tr>
              <th class="font-normal w-7/12 text-right pr-1">Less than 1 
                acre:</th>
                <td class="pl-3 text-left">{{ stats[0].lt_1}}</td>
            </tr>
            <tr class="bg-neutral-50">
              <th class="font-normal w-7/12 text-right pr-1">1 to 2 acres:</th>
                <td class="pl-3 text-left">{{ stats[0].lt_2}}</td>
            </tr>
            <tr>
              <th class="font-normal w-7/12 text-right pr-1">2 to 3 acres:</th>
                <td class="pl-3 text-left">{{ stats[0].lt_3}}</td>
            </tr>
            <tr class="bg-neutral-50">
              <th class="font-normal w-7/12 text-right pr-1">3 to 4 acres:</th>
                <td class="pl-3 text-left">{{ stats[0].lt_4}}</td>
            </tr>
            <tr>
              <th class="font-normal w-7/12 text-right pr-1">4 to 5 acres:</th>
                <td class="pl-3 text-left">{{ stats[0].lt_5}}</td>
            </tr>
            <tr class="bg-neutral-50">
              <th class="font-normal w-7/12 text-right pr-1">5 to 10 acres:</th>
                <td class="pl-3 text-left">{{ stats[0].lt_10}}</td>
            </tr>
            <tr>
              <th class="font-normal w-7/12 text-right pr-1">10 or more 
                  acres:</th>
                <td class="pl-3 text-left">{{ stats[0].gt_10}}</td>
            </tr>
          </tbody>
          </table>
        </div>
    </div>
  </div>
</template>

<script>

  import distribution from '../assets/data/distribution.json';

  export default {
                
    name: 'MiscellaneousComponent',

    props:[
      'dashboard',
      'menu',
      'munis',
      'selection',
      'visible'
    ],
            
    emits: [
      'full_screen',
    ],

    watch:{
      full_screen: function(){
        this.$emit('full_screen', this.full_screen, this.$.type.name);
      },

      selection: {
        deep: true,
        handler(){
          if(!this.selection){
            this.data_available = false;
            return;
          }
          if(!this.selection.id){
            this.data_available = false;
            return;
          }
          this.stats = this.distribution.filter(({ mun_code }) => 
                       mun_code === this.selection.id);

          if(this.stats){
            this.data_available = true;
          }else{
            this.data_available = false;
          }
        }
      },

      visible: {
        deep: true,
        handler(){
          if(this.visible[this.$.type.name]){
            this.show = true;
          }else{
            this.show = false;
          }
        }
      }
    },

    data(){
      return{
        data_available: false,
        distribution,
        full_screen: false,
        show: true,
        stats: null
      }
    },

    methods:{


    }
  }

</script>