<template>
  <div class="flex flex-col w-full h-1/3 md:w-2/5 md:h-full pt-4 mr-6
              place-content-start hover:shadow-xl group overflow-auto">
    <boundaries-component
      v-bind:dashboard = "dashboard"
      v-bind:munis = "munis"
      v-bind:map = "map"
      v-bind:map_loaded = "map_loaded"
      v-bind:popup = "popup"
      @selection="changeSelection"/>
    <constraint-menu
      v-bind:dashboard = "dashboard"
      @selected_constraints="setConstraints"/>
    <layer-menu
      v-bind:dashboard = "dashboard"
      @selected_layers="setLayers"/>
  </div>
</template>
<script>

  import BoundariesComponent from "./BoundariesComponent";
  import ConstraintMenu from "./ConstraintMenu";
  import LayerMenu from "./LayerMenu";

  export default {

    components:{
      'boundaries-component': BoundariesComponent,
      'constraint-menu': ConstraintMenu,
      'layer-menu': LayerMenu
    },

    emits:[
      'selected_constraints',
      'selected_layers',
      'selected_overlay',
      'selection'
    ],

    props:[
      'dashboard',
      'map',
      'map_loaded',
      'munis',
      'popup'
    ],

    data(){
      return{
        selected_constraints: [0, 1],
        selected_layers: {},
        selection: null,
      }
    },

    methods:{
      changeSelection(selection){
        this.selection = selection;
        this.$emit('selection', this.selection)
      },
      setConstraints(selected_constraints){
        this.selected_constraints = selected_constraints;
        this.$emit('selected_constraints', this.selected_constraints)
      },
      setLayers(selected_layers){
        this.selected_layers = selected_layers;
        this.$emit('selected_layers', this.selected_layers)
      },
      changeOverlay(overlay){
        this.$emit('selected_overlay', overlay);
      }
    }

  }

</script>