<template>

  <div class="relative mx-auto top-0 w-full md:max-w-[1920px]">
    <header-component
      v-bind:dashboard = "dashboard"
    />
    <div id="content-container" class="fixed h-full w-full md:max-w-[1920px]">
      <div id="content-wrapper" class="flex h-full flex-col md:flex-row basis-1 grow bg-white border-t border-gray-300">
        <menu-component
          v-bind:constrained = "constrained"
          v-bind:dashboard = "dashboard"
          v-bind:map = "map"
          v-bind:map_loaded = "map_loaded"
          v-bind:munis = "munis"
          v-bind:popup = "popup"
          @selected_constraints = "setConstraints"
          @selected_layers = "setSelectedLayers"
          @selected_overlay="changeOverlay"
          @selection="changeSelection"/>
        <content-component
          v-bind:dashboard = "dashboard"
          v-bind:map = "map"
          v-bind:map_loaded = "map_loaded"
          v-bind:munis = "munis"
          v-bind:selected_constraints = "selected_constraints"
          v-bind:selected_layers = "selected_layers"
          v-bind:selected_overlay = "selected_overlay"
          v-bind:selection = "selection"
          @map="updateMap"
          @map_loaded="updateMapLoaded"
          @popup="updatePopup"/>
      </div>
    </div>
  </div>
  
</template>

<script>

import ContentComponent from "./ContentComponent.vue" 
import HeaderComponent from "./HeaderComponent.vue" 
import constrained from '../assets/data/constrained.json'
import munis from '../assets/data/munis.json'
import MenuComponent from "./MenuComponent.vue" 

  export default {
    name: 'HomeComponent',

    components:{
      "content-component": ContentComponent,
      "header-component": HeaderComponent,
      "menu-component": MenuComponent,
    },

    data() {
      return {
        constrained,
        dashboard: true,
        map: null,
        map_loaded: false,
        munis,
        popup: null,
        selected_constraints: [0, 1],
        selected_layers: {},
        selected_overlay: null,
        selection: null
      }
    },

    created(){
      if(window.location.host.split('.')[0] != 'dashboard'){
        this.dashboard = false;
      }
    },

    methods:{
      changeOverlay(overlay){
        this.selected_overlay = overlay;
      },
      
      changeSelection(selection){
        this.selection = selection;
      },

      setConstraints(selected_constraints){
        this.selected_constraints = selected_constraints;
      },

      setSelectedLayers(selected_layers){
        this.selected_layers = selected_layers;
      },

      updateMap(map){
        this.map = map;
      },

      updateMapLoaded(status){
        this.map_loaded = status;
      },

      updatePopup(popup){
        this.popup = popup;
      }
    }
  }

</script>
<style>

.blah {
  max-width: 75%;
    }
</style>