<template>
  <div v-show="show"
       class="flex shrink-0 grow flex-wrap p-2 overflow-y-auto border-2 
             border-neutral-100 hover:shadow-xl content-start  w-full h-full"
       :class="{'basis-1/2': !full_screen, 
                'min-h-[50%]': !full_screen,
                'max-h-[50%]': !full_screen,
                'basis-1': full_screen,
                'h-full': full_screen}">

    <div class="grid grid-cols-2 w-full">
      
      <div class="align-items-center col-span-1 grid grid-cols-6
                  justify-content-between justify-start gap-2"
            @click="show_link=!show_link"
            :class="show_menu ? 'opacity-1' : 'opacity-0'">
        <span class="apexcharts-menu-icon col-span-1" title="Menu"
              v-show="records.data">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" 
                viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z">
                </path>
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z">
                </path>
          </svg>
        </span>
        <span class="py-0.5 px-7 col-span-5 h-6 cursor-pointer text-xs  cur 
                     text-center border border-neutral-200" 
              :class="[ records.data? 'cursor-pointer':'cursor-default',
                        show_link ? 'opacity-1' : 'opacity-0']"
              title="Download CSV" 
              id="download-csv">
        </span>
      </div>

      <div class="h-6 col-span-1"
          style="font-variation-settings:  'wght' 700, 'GRAD' 0, 'opsz' 48;"
          id="table-expander">
        <span class="font-symbol material-symbols-outlined w-6 float-right 
                    bg-white"
              v-show="!full_screen"   
              @click="full_screen = true">fullscreen</span> 
        <span class="font-symbol material-symbols-outlined w-6 float-right"
              v-show="full_screen"
              @click="full_screen = false">fullscreen_exit</span>
      </div>
    
      <div class="font-mono font-bold text-center mt-16 mx-auto col-span-2
                  text-6xl" v-if="!records.data"
           :class="dashboard?'text-njm_red':'text-fs_orange'">STATS</div>
      <div class="card col-span-2" v-if="records.data">
        <DataTable :value="records.data" tableStyle="min-width: 50rem"
        stripedRows showGridlines selectionMode="single" size="small"
        resizableColumns >

          <template #header>
            <div class="flex flex-none align-items-center
                        justify-content-between basis-1/2 overflow-auto
                        gap-2 border-b border-hcap_logo_green py-1">  
              <span class="w-1/2 float-left basis-1/3">
                <p class="text-base text-900 font-bold whitespace-nowrap"
                  >{{records.title}}</p>
                <p class="text-xs md:text-sm lg:text-base xl:text-lg 
                2xl:text-2xl font-normal whitespace-nowrap pb-2
                text-fs_orange"
                >Remaining Land Summary 
                <!-- <span class="font-semibold">{{label}}</span> -->
                </p>
              </span>
            </div>
          </template>
          <Column v-for="col of columns" 
                  :key="col.field" 
                  :field="col.field" 
                  :header="col.header"
                  sortable>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>

  import Column from 'primevue/column';
  import DataTable from 'primevue/datatable';
  import { json2csv } from 'json-2-csv';
  import munis from '../assets/data/munis.json';
  import constrained from '../assets/data/constrained.json';

  export default {

    name: 'DataComponent',

    components: {
      Column,
      DataTable
    },
    
    props: [
      'dashboard',
      'selection',
      'visible'
    ],
    
    emits: [
      'full_screen',
    ],

    data() {
      return {
        junk: [],
        columns: [
            { field: 'Category', header: 'Category' },
            { field: 'Category Acres', header: 'Category Acres' },
            { field: 'Remaining Acres', header: 'Remaining Acres' },
            { field: 'Remaining %', header: 'Remaining %' },
            { field: 'Constrained Acres', header: 'Constrained Acres' },
            { field: 'Constrained %', header: 'Constrained %' }
        ],
        constrained,
        full_screen: false,
        height: 0,
        label: '',
        munis,
        show: true,
        show_link: false,
        show_menu: false,
        title: ''
      }
    },
    watch:{
      full_screen: function(){
        this.$emit('full_screen', this.full_screen, this.$.type.name);
      },

      selection: {
        deep: true,
        handler(){
          if(this.selection.id){
            this.show_menu  = true;
            this.label = this.munis.filter(({ mun_code }) => 
                    mun_code == this.selection.id)[0].label;
          }
        }
      },

      records:{
        deep:true,
        handler(){
          this.createCsvLink();
        }
      },

      visible: {
        deep: true,
        handler(){
          if(this.visible[this.$.type.name]){
            this.show = true;
          }else{
            this.show = false;
          }
        }
      },
    },
    
    computed: {

      records: function(){
          
        if(!this.selection){
          return [];
        }
        if(!this.selection.id){ 
          return []; 
        }

        const arr = this.constrained
          .filter(({ m }) => m == this.selection.id);

        const total =  arr.filter(({p,s}) => p ==  null &&  s ==  null);
        const sewer  =  arr.filter(({p,s}) => p ===  null && s != null);
        const highland  =  arr.filter(({p,s}) => p == 'h' && s != null);
        const meadowland = arr.filter(({p,s}) => p == 'm' && s != null);
        const pineland  =  arr.filter(({p,s}) => p == 'p' && s != null);
        const Acres  =  arr.filter(({ps}) => /^-?\d+$/.test(ps));
        const center  =  arr.filter(({p}) =>  /^[a-zA-Z]+$/.test(p) &&  
        p !=  null && !['h','m','p'].includes(p));
        let name = '';
        let rows = [];
        let rec = null;

        if(total.length){ 
          rec = total[0];
          if(Object.keys(rec).length != 0){
            rows.push({'Category':'Total Municipality','Category Acres': Number((Math.round(rec.a * 10)/10)).toLocaleString(), 
                        'Remaining Acres': Number((Math.round(rec.na * 10)/10)).toLocaleString(), 'Remaining %': rec.np,
                        'Constrained Acres': Number((Math.round(rec.ca * 10)/10)).toLocaleString(), 'Constrained %': rec.cp});
          }
        }

        if(sewer.length){ 
          rec = (sewer.filter(({s,n}) => s ===  true && n !== null))[0];
          if(Object.keys(rec).length != 0){
            rows.push({'Category':'Sewered acres (in SSA)','Category Acres': Number((Math.round(rec.a * 10)/10)).toLocaleString(), 
                        'Remaining Acres': Number((Math.round(rec.na * 10)/10)).toLocaleString(), 'Remaining %': rec.np,
                        'Constrained Acres': Number((Math.round(rec.ca * 10)/10)).toLocaleString(), 'Constrained %': rec.cp});
          }else{
            rows.push({'Category':'Not Sewered acres','Category Acres': 0, 'Remaining Acres': 0, 'Remaining %': 0,
                        'Constrained Acres': 0, 'Constrained %': 0});
          }
          rec = (sewer.filter(({s}) => s !==  true))[0];
          if(Object.keys(rec).length != 0){
            rows.push({'Category':'Not Sewered','Category Acres': Number((Math.round(rec.a * 10)/10)).toLocaleString(), 
                        'Remaining Acres': Number((Math.round(rec.na * 10)/10)).toLocaleString(), 'Remaining %': rec.np,
                        'Constrained Acres': Number((Math.round(rec.ca * 10)/10)).toLocaleString(), 'Constrained %': rec.cp});
          }else{
            rows.push({'Category':'Not Sewered','Category Acres': 0, 'Remaining Acres': 0, 'Remaining %': 0,
                        'Constrained Acres': 0, 'Constrained %': 0});
          }
        }
        
        rows.push({'Category':' ','Category Acres': '', 
                        'Remaining Acres': '', 'Remaining %': '',
                        'Constrained Acres': '', 'Constrained %': ''});

        if(Acres.length){ 
          //order by planning Acres and sewer:
          Acres.sort((a,b)=>a.ps.localeCompare(b.ps) || Number(b.s)-Number(a.s));  
          
          for(rec of Acres){
            name = "PA: " + rec.p + (rec.s === true?' - Sewered': '- Not Sewered');
            rows.push({'Category':name,'Category Acres': Number((Math.round(rec.a * 10)/10)).toLocaleString(), 
                        'Remaining Acres': Number((Math.round(rec.na * 10)/10)).toLocaleString(), 'Remaining %': rec.np,
                        'Constrained Acres': Number((Math.round(rec.ca * 10)/10)).toLocaleString(), 'Constrained %': rec.cp});
          }
        }

        if(center.length){ 
          center.sort((a,b)=>a.p.localeCompare(b.p) || Number(b.s)-Number(a.s));  
          
          for(rec of center){
            name = 'PC (' + rec.c + '): ' + rec.p + 
                    (rec.s === true?' - Sewered':' - Not Sewered')
            rows.push({'Category':name,'Category Acres': Number((Math.round(rec.a * 10)/10)).toLocaleString(), 
                        'Remaining Acres': Number((Math.round(rec.na * 10)/10)).toLocaleString(), 'Remaining %': rec.np,
                        'Constrained Acres': Number((Math.round(rec.ca * 10)/10)).toLocaleString(), 'Constrained %': rec.cp});
          }
        }

        if(highland.length){ 
          highland.sort((a,b)=>a.p.localeCompare(b.p) || Number(b.s)-Number(a.s));  
          
          for(rec of highland){
            name = "Highlands: " + ((rec.s === true?' - Sewered':' - Not Sewered'))
            rows.push({'Category':name,'Category Acres': Number((Math.round(rec.a * 10)/10)).toLocaleString(), 
                      'Remaining Acres': Number((Math.round(rec.na * 10)/10)).toLocaleString(), 'Remaining %': rec.np,
                      'Constrained Acres': Number((Math.round(rec.ca * 10)/10)).toLocaleString(), 'Constrained %': rec.cp});
          }
        }

        if(meadowland.length){ 
          meadowland.sort((a,b)=>a.p.localeCompare(b.p) || Number(b.s)-Number(a.s));  
          
          for(rec of meadowland){
            name = "Meadowlands: " + ((rec.s === true?' - Sewered':' - Not Sewered'))
            rows.push({'Category':name,'Category Acres': Number((Math.round(rec.a * 10)/10)).toLocaleString(), 
                      'Remaining Acres': Number((Math.round(rec.na * 10)/10)).toLocaleString(), 'Remaining %': rec.np,
                      'Constrained Acres': Number((Math.round(rec.ca * 10)/10)).toLocaleString(), 'Constrained %': rec.cp});
          }
        }

        if(pineland.length){ 
          pineland.sort((a,b)=>a.p.localeCompare(b.p) || Number(b.s)-Number(a.s));  
          
          for(rec of pineland){
            name = "Pinelands: " + ((rec.s === true?' - Sewered':' - Not Sewered'))
            rows.push({'Category':name,'Category Acres': Number((Math.round(rec.a * 10)/10)).toLocaleString(), 
                      'Remaining Acres': Number((Math.round(rec.na * 10)/10)).toLocaleString(), 'Remaining %': rec.np,
                      'Constrained Acres': Number((Math.round(rec.ca * 10)/10)).toLocaleString(), 'Constrained %': rec.cp});
          }
        }

        return {"data": rows};
    }
  },

  methods:{

    createCsvLink(){
      if(!Object.hasOwn(this.records, 'data')){
        return;
      }
      const csvContent = json2csv(this.records.data);

      const blob = new Blob([csvContent], 
                                          { type: 'text/csv;charset=utf-8,' });
      const url = URL.createObjectURL(blob);
      const old_link = document.getElementById('csv-link');
      if(old_link){old_link.remove();}
      let link = document.createElement('a');
      link.classList.add('w-32');
      link.classList.add('border-b');
      link.classList.add('border-neutral-200');
      link.setAttribute('href', url);
      link.setAttribute('id', 'csv-link');
      link.setAttribute('download', this.label + ' Remaining Land.csv');
      document.getElementById('download-csv').append(link);
      document.getElementById('csv-link').innerHTML = 'Download CSV';
    }
  }
}
</script>