<template>
 
  <div class="h-auto pl-4 py-5 text-xs md:text-sm 
              lg:text-base xl:text-xl 2xl:text-2xl align-top items-center">
    <div class="flex-none w-full pl-0 2xl:pl-10 font-normal mb-2"
         :class="dashboard?'text-njm_red':'text-fs_orange'"
    >Boundaries</div>
      <div class="flex flex-wrap pl-2 2xl:pl-14 pr-3 md:pr-5 text-xs lg:text-sm xl:text-xl">
        <div data-id="sewers" 
              class="basis-7/12">
          <input type="checkbox" name="sewers" id="sewers" 
                 value="sewers" data-id="sewers"
                 v-model="selected_layers['sewers']">
          <label for="sewers"  data-id="sewers" 
                  class="pl-2 text-slate-700">Sewer Service Areas</label>
        </div>
        <div data-id="p_areas" 
              class="basis-5/12">
          <input type="checkbox" name="p_areas" id="p_areas" 
                  value="p_areas" data-id="p_areas" 
                  v-model="selected_layers['p_areas']">
          <label for="p_areas"  data-id="p_areas" 
                  class="pl-2 text-slate-700">Planning Areas</label>
        </div>
        <div data-id="p_centers" 
              class="basis-7/12">
          <input type="checkbox" name="p_centers" id="p_centers" 
                  value="p_centers" data-id="p_centers" 
                  v-model="selected_layers['p_centers']">
          <label for="p_centers"  data-id="p_centers" 
                  class="pl-2 text-slate-700">Planning Centers</label>
        </div>
        <div data-id="p_regions" 
              class="basis-5/12">
          <input type="checkbox" name="p_regions" id="p_regions" 
                  value="p_regions" data-id="p_regions" 
                  v-model="selected_layers['p_regions']">
          <label for="p_regions"  data-id="p_regions" 
                  class="pl-2 text-slate-700">Planning Regions</label>
        </div>
      </div>
    <div class="flex-none w-full pl-0 2xl:pl-10  font-normal mt-6 mb-2"
         :class="dashboard?'text-njm_red':'text-fs_orange'"
    >Constraints</div>
      <div class="flex flex-wrap pl-0 2xl:pl-10 pr-1 lg:pr-5 text-xs lg:text-sm xl:text-lg 2xl:text-xlg">
        <!-- <div data-id="buffered_roads" 
             class="basis-7/12">
          <input type="checkbox" name="overlay" id="buffered_roads" 
                 value="true" data-id="buffered_roads" 
                 v-model="selected_layers['buffered_roads']">
          <label for="buffered_roads"  data-id="buffered_roads" 
                 class="pl-2 text-slate-700">Buffered Roads</label>
        </div> -->
        <div data-id="parcel_voids" 
             class="basis-5/12 pl-0 xl:pl-2">
          <input type="checkbox" name="overlay" id="parcel_voids" 
                 value="true" data-id="parcel_voids" 
                 v-model="selected_layers['parcel_voids']">
          <label for="parcel_voids"  data-id="parcel_voids" 
                 class="pl-2 text-slate-700">Road ROW w Buffer</label>
        </div>
        <div data-id="high_slopes" 
             class="basis-7/12">
          <input type="checkbox" name="overlay" id="high_slopes" 
                 value="true" data-id="high_slopes" 
                 v-model="selected_layers['high_slopes']">
          <label for="high_slopes"  data-id="high_slopes" 
                 class="pl-2 text-slate-700">High Slopes</label>
        </div>
        <div data-id="urban_land" 
             class="basis-5/12 pl-0 xl:pl-2">
          <input type="checkbox" name="overlay" id="urban_land" 
                 value="true" data-id="urban_land" 
                 v-model="selected_layers['urban_land']">
          <label for="urban_land"  data-id="urban_land" 
                 class="pl-2 text-slate-700">Urban Land</label>
        </div>
        <div data-id="dep_openspace" 
             class="basis-7/12">
          <input type="checkbox" name="overlay" id="dep_openspace" 
                 value="true" data-id="dep_openspace" 
                 v-model="selected_layers['dep_openspace']">
          <label for="dep_openspace"  data-id="dep_openspace" 
                 class="pl-2 text-slate-700">Open Space (DEP)</label>
        </div>
        <div data-id="rowan_openspace" 
             class="basis-5/12 pl-0 xl:pl-2">
          <input type="checkbox" name="overlay" id="rowan_openspace" 
                 value="true" data-id="rowan_openspace" 
                 v-model="selected_layers['rowan_openspace']">
          <label for="rowan_openspace"  data-id="rowan_openspace" 
                 class="pl-2 text-slate-700">Open Space (Rowan)</label>
        </div>
        <div data-id="fema_tidal" 
             class="basis-7/12">
          <input type="checkbox" name="overlay" id="fema_tidal" 
                 value="true" data-id="fema_tidal" 
                 v-model="selected_layers['fema_tidal']">
          <label for="fema_tidal"  data-id="fema_tidal" 
                 class="pl-2 text-slate-700">FEMA Tidal Flood - 5'</label>
        </div>
        <div data-id="fema_100" 
             class="basis-5/12 pl-0 xl:pl-2">
          <input type="checkbox" name="overlay" id="fema_100" 
                 value="true" data-id="fema_100" 
                 v-model="selected_layers['fema_100']">
          <label for="fema_100"  data-id="fema_100" 
                 class="pl-2 text-slate-700">FEMA Flood Plus 3'</label>
        </div>
        <div data-id="njfpp" 
             class="basis-7/12">
          <input type="checkbox" name="overlay" id="njfpp" 
                 value="true" data-id="njfpp" 
                 v-model="selected_layers['njfpp']">
          <label for="njfpp"  data-id="njfpp" 
                 class="pl-2 text-slate-700">Preserved Farms</label>
        </div>
        <div data-id="c1" 
             class="basis-5/12 pl-0 xl:pl-2">
          <input type="checkbox" name="overlay" id="c1" 
                 value="true" data-id="c1" 
                 v-model="selected_layers['buffered_c1_streams']">
          <label for="c1"  data-id="c1" 
                 class="pl-2 text-slate-700">Buffered C1 Streams</label>
        </div>
        <!-- <div data-id="padus" 
             class="basis-5/12 pl-0 xl:pl-2">
          <input type="checkbox" name="overlay" id="padus" 
                 value="true" data-id="padus" 
                 v-model="selected_layers['padus']">
          <label for="padus"  data-id="padus" 
                 class="pl-2 text-slate-700">Protected Areas (PAD US)</label>
        </div> -->
        <div data-id="buffered_nhd_streams" 
             class="basis-7/12">
          <input type="checkbox" name="overlay" id="buffered_nhd_streams" 
                 value="true" data-id="buffered_nhd_streams" 
                 v-model="selected_layers['buffered_nhd_streams']">
          <label for="buffered_nhd_streams"  data-id="buffered_nhd_streams" 
          class="pl-2 text-slate-700">Buffered Streams (NHD)</label>
        </div>
        <div data-id="buffered_nhd_waterbodies" 
             class="basis-5/12 pl-0 xl:pl-2">
          <input type="checkbox" name="overlay" id="buffered_nhd_waterbodies" 
                 value="true" data-id="buffered_nhd_waterbodies" 
                 v-model="selected_layers['buffered_nhd_waterbodies']">
          <label for="buffered_nhd_waterbodies"  data-id="buffered_nhd_waterbodies" 
          class="pl-2 text-slate-700">Buffered Water (NHD)</label>
        </div>
        <div data-id="buffered_dep_water" 
             class="basis-7/12">
          <input type="checkbox" name="overlay" id="buffered_dep_water" 
                 value="true" data-id="buffered_dep_water" 
                 v-model="selected_layers['buffered_dep_water']">
          <label for="buffered_dep_water"  data-id="buffered_dep_water" 
          class="pl-2 text-slate-700">Buffered Wetlands/Water (DEP)</label>
        </div>
      </div>
  </div>

</template>

<script>

  export default {

    name: "LayerMenu",

    emits:[
      'selected_layers'
    ],

    props:[
      'dashboard'
    ],

    data(){
      return{
          'selected_layers': {
            'buffered_roads': false,
            'parcel_voids': false,
            'high_slopes': false,
            'urban_land': false,
            'dep_openspace': false,
            'rowan_openspace': false,
            'fema_tidal': false,
            'fema_100': false,
            'njfpp': false,
            'padus': false,
            'buffered_c1_streams': false,
            'buffered_nhd_streams': false,
            'buffered_nhd_waterbodies': false,
            'buffered_dep_water': false,
            'sewers': false,
            'p_areas': false,
            'p_centers': false,
            'p_regions': false,
          },
        }
    },

    watch:{

      selected_layers: {
        deep: true,
        handler(){
          this.$emit('selected_layers', this.selected_layers);
        }
      }
    },
  }

</script>
