<template>
  
  <div class="flex bg-white w-full md:w-2/5 "
        id="header">
    <div class="h-20 w-full my-auto flex">
      <div class="flex flex-nowrap items-center my-auto">
        <div class="bg-white flex flex-nowrap basis-24 md:basis-36">
          <img class="pb-1 pl-4 mx-auto border-0"  
               :src="dashboard?njmap_logo:fair_share_logo">
        </div>
        <div class="flex text-left text-xl lg:text-3xl ml-4 lg:ml-8 font-normal font-roboto text-black">
          <span>Municipal Remaining Lands</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import fair_share_logo from "../assets/img/cropped-loog-fairshare-orange.webp"  
  import njmap_logo from "../assets/img/njmap.svg"  

  export default {
    name: 'HomeComponent',

    data() {
      return {
        fair_share_logo,
        njmap_logo
      }
    },

    props: {
      dashboard: Boolean,
      msg: String
    }
  }

</script>
